<template>
  <div id="app" class="phishar" :class="{ 'phishar--visible': animation.page }">

    <div class="navigation">

      <div class="navigation__logo">
        <img src="@/assets/images/logo-upd.svg" alt="AmplifiAR">
      </div>

    </div>

    <div class="content">

        <h1>
          <span class="content__aiar">{{ animation.aiar.value }}</span>
          paradigm shift in
          <span class="content__domain">{{ animation.domain.value }}</span>
        </h1>
        <h2>We're in stealth</h2>

    </div>

    <div class="footer">
      <div class="footer__label">
        Join us
      </div>
      <span class="footer__links">
        <a href="https://phishar.talentlyft.com/" target="_blank">Careers</a> &nbsp; | &nbsp;
        <a href="mailto:investments@phishar.com">investments@amplifiar.com</a> &nbsp; | &nbsp;
        <a href="mailto:partnerships@phishar.com">partnerships@amplifiar.com</a>
      </span>
    </div>

    <video
      ref="background"
      class="background"
      :class="{ 'background--visible': animation.background }"
      src="@/assets/videos/background.mp4"
      autoplay muted loop
    ></video>

  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      animation: {
        background: false,
        page: false,
        aiar: {
          value: 'A',
          options: ['A', 'AI', 'AR'],
          next: 0
        },
        domain: {
          value: 'security',
          options: ['security', 'productivity', 'creativity'],
          next: 0
        }
      }
    }
  },
  methods: {
    intro() {
      // this.$refs.background.playbackRate = 0.4;

      setTimeout(() => {
        this.animation.page = true
      }, 1500)

      setTimeout(() => {
        this.animation.background = true
      }, 1500)
    },
    tickerAiar() {
      this.animation.aiar.value = this.animation.aiar.options[this.animation.aiar.next]
      this.animation.aiar.next = ((this.animation.aiar.next + 1) >= this.animation.aiar.options.length) ? 0 : (this.animation.aiar.next + 1)
    },
    tickerDomain() {
      this.animation.domain.value = this.animation.domain.options[this.animation.domain.next]
      this.animation.domain.next = ((this.animation.domain.next + 1) >= this.animation.domain.options.length) ? 0 : (this.animation.domain.next + 1)
    }
  },
  mounted() {
    this.intro()
    setInterval(this.tickerAiar, 750)
    setInterval(this.tickerDomain, 3000)
  }
}
</script>
